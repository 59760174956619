export function useHumanDate() {
  const humanDate = (date, locale = 'ru', format = null) => {
    const newDate = new Date(date)
    if (!format) {
      format = { year: 'numeric', month: 'short', day: 'numeric' }
    }
    const formatter = new Intl.DateTimeFormat(locale, format)
    return formatter.format(newDate)
  }

  const humanDateTime = (date, locale) => {
    return humanDate(date, locale, { hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'short', day: 'numeric', hour12: false })
  }
  return { humanDate, humanDateTime }
}
