<template>
  <div v-if="list.length === 0">{{ $t('emptyImessageSettingsText') }}</div>

  <div class="table-responsive adaptive-table sentMessageTable" v-if="list.length > 0">
    <table class="table table-striped table-hover " style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :class="item === 'text' ? 'col-md-4' : ''" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id">
          <td :data-label="$t('platform')">
            <p class="mb-2">
              <span class="text-left font-weight-bold">{{ $t(`imessagePlatforms.${getSelectedFieldName(row.type)}`) }}:</span> {{ row.value }} {{ row.session_term_type }}
            </p>
            <p class="mb-2">
              <span class="text-left font-weight-bold">{{ $t('authSecretKey') }}: </span> {{ row.sdk_key }} <copyButton :text="row.sdk_key"></copyButton>
            </p>
            <p class="mb-2">
              <hint title="forms.buyPackages.terms_hint" placement="top"><span class="text-left font-weight-bold">{{ $t('terms') }}:</span> {{ row.session_term }} {{ row.session_term_type }}</hint>
            </p>
            <p class="mb-2">
              <hint title="forms.buyPackages.terms_hint" placement="top"><span class="text-left font-weight-bold">{{ $t('allowMultiSession') }}:</span> {{ row.multi_session ? '✅' : '❌' }}</hint>
            </p>
            <p class="mb-2">
              <hint title="forms.buyPackages.terms_hint" placement="top"><span class="text-left font-weight-bold">{{ $t('callbackUrl') }}:</span> {{ row.status_callback || '-' }}</hint>
            </p>
            <p class="mb-2">
              <hint title="forms.buyPackages.terms_hint" placement="top"><span class="text-left font-weight-bold">{{ $t('callbackHeader') }}:</span> {{ row.status_callback_header || '-' }}</hint>
            </p>
          </td>
          <td :data-label="$t('actions')">
            <button
                class="btn btn-primary btn-sm mt-2 mr-2"
                @click.prevent="$.emit('edit', row)"
              ><i class="feather icon-edit-2 mx-1"></i>{{ $t('edit') }}</button>
            <modal-wrapper>
                <template v-slot="{ close }">
                  <confirmation-modal
                    :close="close"
                    :content="$t('confirmDeletion')"
                    @confirm="$.emit('delete', row.id)"
                  ></confirmation-modal>
                </template>
                <template v-slot:trigger="{ open }">
                  <button
                    class="btn btn-danger btn-sm mt-2"
                    :disabled="isDisabledReset"
                    @click="open"
                  ><hint title="forms.buyPackages.terms_hint" placement="top">
                    <i class="feather icon-trash mx-1"></i>
                  </hint>
                  </button>
                </template>
              </modal-wrapper>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useHumanDate } from '@/application/composables/humanDate.js'
import { useImessageAuth } from '@/application/composables/imessageAuth.js'

export default {
  name: 'LookupHistoryTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  emits: ['delete', 'edit'],
  components: {
    copyButton: defineAsyncComponent(() => import('@/application/components/elements/CopyButton.vue')),
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
    hint: defineAsyncComponent(() => import('@/application/components/elements/Hint.vue')),
  },
  setup() {
    const { humanDateTime, humanDate } = useHumanDate()
    const { getSelectedFieldName } = useImessageAuth([])

    const fields = ['platform', 'actions']

    return {
      humanDateTime,
      humanDate,
      fields,
      getSelectedFieldName,
    }
  },
}
</script>
